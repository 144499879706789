import blankArray from './blankArray';

const sepChar = '_';

const compressArray = (ary) => ary.reduce((acc, e) => acc + 2 ** e, 0);
const compressNestedArrays = (choices) => choices.map(compressArray).join(sepChar);
const decompress = (str) => str.split(sepChar)
  .map(Number)
  .map((n) => {
    if (n === 0) return [];
    const N = Math.floor(Math.log2(n)) + 1;
    // eslint-disable-next-line no-bitwise
    return blankArray(N).filter((i) => n & 2 ** i);
  });

export default function arrayCompression(input, compress) {
  if (compress) {
    return compressNestedArrays(input);
  }
  return decompress(input);
}
