import blankArray from './blankArray';

export default function shuffle(array, rndgen) {
  const indices = blankArray(array.length);
  for (let i = indices.length - 1; i > 0; i--) {
    const j = Math.floor(rndgen() * (i + 1));
    [indices[i], indices[j]] = [indices[j], indices[i]];
  }
  return indices.map((i) => array[i]);
}
