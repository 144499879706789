import blankArray from './blankArray';
import cElement from './cElement';
import shuffle from './shuffle';

const moveFocus = (x, y, delta) => [
  (x + delta[0] + 5) % 5,
  (y + delta[1] + 5) % 5,
];

function btnClickListener(btn) {
  return (e) => {
    document.querySelectorAll('table button')
      .forEach((b) => b.setAttribute('tabindex', -1));
    btn.setAttribute('tabindex', 0);
    btn.setAttribute('aria-pressed', btn.getAttribute('aria-pressed') === 'false');
  }
}

function btnKeyListener(e) {
  const btn = e.target;
  let x = Number(btn.getAttribute('data-x'));
  let y = Number(btn.getAttribute('data-y'));

  let delta;
  if (e.key === 'ArrowLeft') {
    delta = [-1, 0];
  } else if (e.key === 'ArrowRight') {
    delta = [1, 0];
  } else if (e.key === 'ArrowUp') {
    delta = [0, -1];
  } else if (e.key === 'ArrowDown') {
    delta = [0, 1];
  } else {
    return;
  }
  [x, y] = moveFocus(x, y, delta);
  if (x === 2 && y === 2) {
    [x, y] = moveFocus(x, y, delta);
  }

  document.querySelector(`button[data-x="${x}"][data-y="${y}"]`).focus();
}

function createBingoButton(text) {
  const btn = cElement('button', {
    tabindex: -1,
    innerHTML: text,
    onkeydown: btnKeyListener,
  });
  btn.addEventListener("click", btnClickListener(btn));
  btn.setAttribute('aria-pressed', false);

  const bg = cElement('div', { className: 'bg' });
  bg.setAttribute('aria-hidden', true);
  btn.append(bg);

  return btn;
}

function createFreeSpace() {
  const btn = cElement('button', {
    innerText: 'FREE SPACE',
    tabindex: -1,
  });
  btn.setAttribute('aria-disabled', true);
  btn.setAttribute('aria-pressed', true);
  return btn;
}

function makeSquare(text, i, j) {
  const td = cElement('td');
  td.setAttribute('role', 'gridcell');
  const btn = i === 2 && j === 2 ? createFreeSpace() : createBingoButton(text);
  btn.setAttribute('data-x', j);
  btn.setAttribute('data-y', i);
  td.append(btn);
  return td;
}

export default function makeCard(seed, squares) {
  // eslint-disable-next-line no-undef, new-cap
  const rndgen = new aleaPRNG(seed);
  const randomIndex = shuffle(blankArray(squares.length), rndgen);

  const tbody = cElement('tbody');

  const rows = blankArray(5).map((i) => {
    const row = cElement('tr');
    row.setAttribute('role', 'row');

    const cells = blankArray(5)
      .map((j) => makeSquare(
        squares[randomIndex[j + i * 5]],
        i,
        j,
      ));
    row.append(...cells);
    return row;
  });
  tbody.append(...rows);

  const table = cElement('table');
  table.setAttribute('role', 'grid');
  table.append(tbody);
  return table;
}
