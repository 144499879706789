import cElement from './cElement';

function makeOption(option, value) {
  const label = cElement('label', { innerText: option.text });
  label.prepend(cElement('input', {
    type: 'checkbox',
    value,
  }));
  const li = cElement('li');
  li.append(label);
  return li;
}

export default function renderQuestion(data) {
  const h2 = cElement('h2', { innerText: data.prompt });

  const list = cElement('ul');
  list.append(...data.options.map((e, i) => makeOption(e, i)));

  const continueBtn = cElement('button', {
    innerText: 'Continue',
    type: 'submit',
  });

  const form = cElement('form', { action: '#' });
  form.append(h2, list, continueBtn);
  return form;
}
