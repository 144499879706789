import renderQuestion from './renderQuestion';

const askAQuestion = (qData, elm) => new Promise((resolve) => {
  const form = renderQuestion(qData);
  const submitHandler = (e) => {
    e.preventDefault();
    const selected = form.querySelectorAll('input:checked');
    resolve(Array.from(selected).map((i) => Number(i.value)));
  };
  form.addEventListener('submit', submitHandler);
  // eslint-disable-next-line no-param-reassign
  elm.innerHTML = '';
  elm.append(form);
});

const askQuestions = (data, elm) => data.reduce(
  (chain, q) => chain.then(async (e) => {
    const r = await askAQuestion(q, elm);
    return [...e, r];
  }),
  Promise.resolve([]),
);

export default function askForParams(data, elm) {
  return askQuestions(data, elm);
}
