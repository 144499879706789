import ajax from './ajax';
import arrayCompression from './arrayCompression';
import askForParams from './askForParams';
import getURLParam from './getURLParam';
import makeCard from './makeCard';
import newSeed from './newSeed';

window.onload = () => {
  function getSeed(url = window.location.search) {
    const seed = getURLParam(url, 'seed');
    if (!seed) return newSeed();
    return seed;
  }

  const getChoices = (url = window.location.search) => {
    const choices = getURLParam(url, 'choices');
    try {
      return arrayCompression(choices, false);
    // eslint-disable-next-line no-empty
    } catch { }
  };

  const getCardSlot = () => document.getElementById('card-slot');

  const fillSlot = (elm) => {
    const slot = getCardSlot();
    slot.innerHTML = '';
    slot.append(elm);
  };

  function updateURL(seed, choices) {
    const params = new URLSearchParams();
    params.set('seed', seed);
    params.set('choices', arrayCompression(choices, true));
    // eslint-disable-next-line no-restricted-globals
    history.pushState({ page: 1 }, 'Horror Bingo', `?${params.toString()}`);
  }

  function getSquares(choiceAry, data) {
    const squares = [...data.Core, ...choiceAry
      .map((c, i) => c.map((e) => data.Custom[i].options[e].squares))
      .flat()
      .flat(),
    ];
    return Array.from(new Set(squares));
  }

  function newCard(seed, choices, jsonData) {
    try {
      const squares = getSquares(choices, jsonData);
      fillSlot(makeCard(seed, squares));
    } catch (e) {
      window.location.replace('/');
    }
  }

  function newCardListener(choices, jsonData) {
    return () => {
      const seed = newSeed();
      updateURL(seed, choices);
      newCard(seed, choices, jsonData);
    };
  }

  function stateListener(jsonData) {
    return (e) => {
      const seed = getSeed(e.target.location.search);
      const choices = getChoices(e.target.location.search);
      if (choices) {
        newCard(seed, choices, jsonData);
      } else {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    };
  }

  async function init() {
    const jsonData = await ajax({ url: 'lists.json' });

    const seed = getSeed();

    let choices = getChoices();
    if (!choices) {
      choices = await askForParams(jsonData.Custom, getCardSlot());
      updateURL(seed, choices);
    }
    newCard(seed, choices, jsonData);

    document.getElementById('newcard').addEventListener(
      'click',
      newCardListener(choices, jsonData),
    );

    window.addEventListener(
      'popstate',
      stateListener(jsonData),
    );
  }

  init();
};
