export default function ajax(props) {
  const args = {
    method: 'GET',
    data: {},
    contentType: 'application/x-www-form-urlencoded',
    ...props,
  };
  const xhr = new XMLHttpRequest();
  xhr.open(args.method, args.url, true);
  xhr.setRequestHeader('Content-Type', args.contentType);

  const data = new URLSearchParams(args.data).toString();

  return new Promise((resolve) => {
    xhr.send(data);
    xhr.onload = () => resolve(JSON.parse(xhr.response));
  });
}
